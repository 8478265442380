import React from "react";
import { Box } from "@chakra-ui/react";
import Slider from "react-slick";

const settings = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  autoplay: true,
  pauseOnHover: false,
};

const HeroSlider = () => {
  return (
    <Box
      top="0"
      left="0"
      right="0"
      bottom="0"
      position="absolute"
      overflow="hidden"
      backgroundColor="primary.700"
    >
      <Slider {...settings}>
        <Box
          height="100%"
          maxH={{ base: "260px", md: "none" }}
          backgroundImage="url('/img/insurefarm-hero-bg-1.jpg')"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition="left"
        />
        <Box
          maxH={{ base: "260px", md: "none" }}
          height="100%"
          backgroundImage="url('/img/insurefarm-hero-bg-2.jpg')"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition="left"
        />
        <Box
          maxH={{ base: "260px", md: "none" }}
          height="100%"
          backgroundImage="url('/img/insurefarm-hero-bg-3.jpg')"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition="left"
        />
      </Slider>
    </Box>
  );
};

export default HeroSlider;
