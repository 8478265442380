import React from "react";
import { Button, InputGroup, Input, InputRightElement } from "@chakra-ui/react";
import { navigate } from "gatsby";

const InputButton = ({ value, setValue }) => {
  return (
    <InputGroup h="66px" size="lg" width="100%">
      <Input
        onChange={(e) => setValue(e.target.value)}
        pr="256px"
        bg="white"
        size="lg"
        h="66px"
        value={value}
        placeholder="Enter zipcode"
      />
      <InputRightElement height="66px" width="248px">
        <Button
          colorScheme="primary"
          h="58px"
          width="240px"
          borderRadius="4px"
          boxShadow="none"
          onClick={() =>
            navigate("/find-plan", {
              state: {
                value,
              },
            })
          }
        >
          FIND PLAN
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};

export default InputButton;
