import React from "react";
import { Box, Text } from "@chakra-ui/react";
import Slider from "react-slick";

const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnHover: false,
};

const testimonials = [
  {
    name: "John K.",
    position: "Seniors Concierge Client",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nec magna ligula. Etiam placerat faucibus est, ac consectetur ante ullamcorper ut. Proin non nulla non mauris tristique efficitur.",
  },
  {
    name: "Tyler A.",
    position: "Seniors Concierge Client",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nec magna ligula. Etiam placerat faucibus est, ac consectetur ante ullamcorper ut. Proin non nulla non mauris tristique efficitur.",
  },
  {
    name: "Christa M.",
    position: "Seniors Concierge Client",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nec magna ligula. Etiam placerat faucibus est, ac consectetur ante ullamcorper ut. Proin non nulla non mauris tristique efficitur.",
  },
];

const TestimonialSlider = () => {
  const renderTestimonials = () => {
    return testimonials.map((testimonial) => {
      return (
        <Box
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          px={{ base: "24px", md: "6rem" }}
        >
          <Text
            fontWeight="300"
            fontSize={{ base: "20px", md: "36px" }}
            lineHeight={{ base: "40px", md: "64px" }}
            color="#333333"
            mb="8"
          >
            {testimonial.description}
          </Text>
          <Text mb={{ base: 4, md: 0 }}>
            {testimonial.name}
            <br />
            {testimonial.position}
          </Text>
        </Box>
      );
    });
  };

  return (
    <Box
      height={{ base: "100%", md: "440px" }}
      position="relative"
      className="wrapper-lg"
    >
      <Text
        as="h2"
        fontSize={{ base: "100px", md: "120px", lg: "144px" }}
        position="absolute"
        top={{ base: "-4rem", md: "-4rem", lg: "-6rem" }}
        left={{ base: "-1rem", md: "-2rem", lg: "-4rem" }}
      >
        “
      </Text>
      <Text
        as="h2"
        fontSize={{ base: "100px", md: "120px", lg: "144px" }}
        position="absolute"
        bottom={{ base: 0, md: "4rem" }}
        right="0"
        transform="scaleX(-1)"
      >
        “
      </Text>
      <Box>
        <Slider {...settings}>{renderTestimonials()}</Slider>
      </Box>
    </Box>
  );
};

export default TestimonialSlider;
