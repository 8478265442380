import React, { useState } from "react";
import Layout from "../components/Layout";
import {
  Box,
  List,
  Text,
  ListItem,
  ListIcon,
  Center,
  Button,
  Icon,
  Flex,
  Input,
} from "@chakra-ui/react";
import HeroSlider from "../components/HeroSlider";
import InputButton from "../components/InputButton";
import { MdCheckCircle } from "react-icons/md";
import { AiOutlinePhone } from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";
import TestimonialSlider from "../components/TestimonialsSlider";
import { useInView, InView } from "react-intersection-observer";
import ContactSection from "../components/ContactSection";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { navigate } from "gatsby-link";

const LandingPage = ({ pathname }) => {
  const [zipcode, setZipcode] = useState(null);
  const { ref: testimonialsRef, inView: testimonialsInView } = useInView({
    delay: 100,
    threshold: 0.5,
  });
  const breakpoints = useBreakpoint();

  return (
    <Layout pathname={pathname}>
      <Box
        as="section"
        position="relative"
        flex="1"
        height="100%"
        background="#333333"
        display="flex"
        alignItems="center"
        minH={{ base: "calc(100vh - 113px)", md: "calc(100vh - 154px)" }}
        maxH="672px"
      >
        <Box
          height="100%"
          className="wrapper-xl"
          marginTop={{ base: "auto", md: 0 }}
          marginBottom={{ base: 8, md: 0 }}
        >
          <Box
            zIndex="9"
            position="relative"
            backgroundColor="#ffffff"
            padding={{ base: "24px", md: "3rem 4rem" }}
            paddingBottom={{ base: "32px", md: "3.5rem" }}
            width={{ base: "100%", md: "560px", lg: "600px" }}
            marginLeft="auto"
            my={{ base: 0, md: "auto" }}
            boxShadow="0px 4px 36px rgba(0, 0, 0, 0.16)"
            borderRadius="8px"
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <Text
                fontSize={{ base: "32px", md: "40px", lg: "48px" }}
                lineHeight={{ base: "48px", md: "56px", lg: "64px" }}
                as="h1"
                mb={{ base: 2, md: 4 }}
                color="#333333"
              >
                Let's get your medicare plan
              </Text>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6, delay: 0.6 }}
            >
              <Text
                fontSize={{ base: "18px", md: "20px" }}
                lineHeight="32px"
                as="p"
                color="#333333"
                mb={{ base: 6, md: 12 }}
              >
                We're here to help answer your questions and understand your
                Medicare plan options.
                <br />
                Enter your zipcode below to get a <strong>FREE</strong> quote.
              </Text>
            </motion.div>

            <motion.div
              animate={{ y: 0, opacity: 1 }}
              initial={{ y: "-4rem", opacity: 0 }}
              transition={{ duration: 1, delay: 1.2 }}
            >
              {breakpoints.md ? (
                <InputButton value={zipcode} setValue={setZipcode} />
              ) : (
                <Flex flexDir="column">
                  <Input
                    onChange={(e) => setZipcode(e.target.value)}
                    bg="white"
                    size="lg"
                    h="54px"
                    value={zipcode}
                    placeholder="Enter zipcode"
                    mb="4"
                  />
                  <Button
                    colorScheme="primary"
                    h="58px"
                    borderRadius="4px"
                    boxShadow="none"
                    onClick={() =>
                      navigate("/find-plan", {
                        state: {
                          zipcode,
                        },
                      })
                    }
                  >
                    FIND PLAN
                  </Button>
                </Flex>
              )}
            </motion.div>
          </Box>
        </Box>

        <Box>
          <HeroSlider />
        </Box>
      </Box>

      <Box as="section" py="6rem" bg="#094B46">
        <Center mb="16" textAlign="center">
          <Text
            as="h2"
            fontWeight="bold"
            fontSize={{ base: "32px", lg: "48px" }}
            lineHeight={{ base: "32px", lg: "48px" }}
            color="#FFFFFF"
          >
            What to expect from Medicare?
          </Text>
        </Center>
        <InView triggerOnce threshold={0.4}>
          {({ inView, ref }) => (
            <Box
              ref={ref}
              display="grid"
              className="wrapper-xl"
              gridTemplateColumns={{ base: "none", md: "repeat(3,1fr)" }}
              gridTemplateRows={{ base: "repeat(3,auto)", md: "none" }}
              gridColumnGap={{ base: 0, md: "40px" }}
              gridRowGap={{ base: "40px", md: 0 }}
            >
              {inView && (
                <motion.div
                  animate={{ y: 0, opacity: 1 }}
                  initial={{ y: "4rem", opacity: 0 }}
                  transition={{ duration: 0.8, delay: 0.4 }}
                >
                  <Box
                    padding={{ base: "24px", md: "40px" }}
                    bg="#FFFFFF"
                    height="100%"
                    boxShadow="0px 4px 36px rgba(0, 0, 0, 0.16)"
                    borderRadius="8px"
                  >
                    <Text
                      fontSize="32px"
                      lineHeight="32px"
                      as="h2"
                      mb="4"
                      color="#333333"
                    >
                      Medicare
                      <br />
                      Basics
                    </Text>
                    <Text as="p" mb="6">
                      Fee-for-service coverage under which the government
                      directly pays your providers the claims for your Medicare.
                    </Text>
                    <List spacing={3}>
                      <ListItem>
                        <ListIcon as={MdCheckCircle} color="green.500" />
                        PART A HOSPITAL COVERAGE (Hospital Insurance)
                      </ListItem>
                      <ListItem>
                        <ListIcon as={MdCheckCircle} color="green.500" />
                        PART B MEDICAL COVERAGE (Doctor Services & Out Patient
                        Care)
                      </ListItem>
                      <ListItem>
                        <ListIcon as={MdCheckCircle} color="green.500" />
                        PART C ADVANTAGE (Managed Care Plans)
                      </ListItem>
                      <ListItem>
                        <ListIcon as={MdCheckCircle} color="green.500" />
                        PART D DRUG COVERAGE (Prescription Insurance)
                      </ListItem>
                    </List>
                  </Box>
                </motion.div>
              )}
              {inView && (
                <motion.div
                  animate={{ y: 0, opacity: 1 }}
                  initial={{ y: "4rem", opacity: 0 }}
                  transition={{ duration: 0.8, delay: 0.8 }}
                >
                  <Box
                    padding={{ base: "24px", md: "40px" }}
                    bg="#FFFFFF"
                    height="100%"
                    boxShadow="0px 4px 36px rgba(0, 0, 0, 0.16)"
                    borderRadius="8px"
                  >
                    <Text
                      fontSize="32px"
                      lineHeight="32px"
                      as="h2"
                      mb="4"
                      color="#333333"
                    >
                      Medicare Supplements
                    </Text>
                    <Text as="p" mb="6">
                      A Medicare supplement insurance policy helps pay your
                      share (coinsurance, co-payments, or deductibles) of the
                      costs of Medicare-covered services.
                    </Text>
                    <List spacing={3}>
                      <ListItem>
                        <ListIcon as={MdCheckCircle} color="green.500" />
                        ENJOY LOW OUT-OF-POCKET COSTS
                      </ListItem>
                      <ListItem>
                        <ListIcon as={MdCheckCircle} color="green.500" />
                        NO PROVIDER NETWORKS, NO REFERRALS
                      </ListItem>
                      <ListItem>
                        <ListIcon as={MdCheckCircle} color="green.500" />
                        TAKE YOUR COVERAGE COAST TO COAST
                      </ListItem>
                      <ListItem>
                        <ListIcon as={MdCheckCircle} color="green.500" />
                        RARELY RECEIVE A BILL FOR COVERED SERVICES
                      </ListItem>
                    </List>
                  </Box>
                </motion.div>
              )}
              {inView && (
                <motion.div
                  animate={{ y: 0, opacity: 1 }}
                  initial={{ y: "4rem", opacity: 0 }}
                  transition={{ duration: 0.8, delay: 1.2 }}
                >
                  <Box
                    padding={{ base: "24px", md: "40px" }}
                    bg="#FFFFFF"
                    height="100%"
                    boxShadow="0px 4px 36px rgba(0, 0, 0, 0.16)"
                    borderRadius="8px"
                  >
                    <Text
                      fontSize="32px"
                      lineHeight="32px"
                      as="h2"
                      mb="4"
                      color="#333333"
                    >
                      Medicare
                      <br />
                      Part D (RX)
                    </Text>
                    <Text as="p" mb="6">
                      Medicare Part D is a prescription drug option run by
                      private insurance companies approved by and under contract
                      with Medicare to help cover the cost of prescription
                      drugs.
                    </Text>
                    <List spacing={3}>
                      <ListItem>
                        <ListIcon as={MdCheckCircle} color="green.500" />
                        LOWER YOUR PRESCRIPTION DRUG COSTS
                      </ListItem>
                      <ListItem>
                        <ListIcon as={MdCheckCircle} color="green.500" />
                        HELP PROTECT AGAINST HIGHER COSTS IN THE FUTURE
                      </ListItem>
                      <ListItem>
                        <ListIcon as={MdCheckCircle} color="green.500" />
                        OFFERED BY PRIVATE INSURANCE COMPANIES
                      </ListItem>
                      <ListItem>
                        <ListIcon as={MdCheckCircle} color="green.500" />
                        ANNUAL ENROLLMENT PERIOD OCTOBER-DECEMBER
                      </ListItem>
                    </List>
                  </Box>
                </motion.div>
              )}
            </Box>
          )}
        </InView>
      </Box>

      <Box as="section" py="6rem" bg="#ffffff">
        <InView triggerOnce threshold={0.4}>
          {({ inView, ref }) => (
            <Box
              ref={ref}
              display="grid"
              className="wrapper-xl"
              gridTemplateColumns={{ base: "none", md: "1.5fr 2fr" }}
              gridTemplateRows={{ base: "auto auto", md: "none" }}
              gridColumnGap={{ base: 0, md: "80px" }}
              gridRowGap={{ base: "40px", md: 0 }}
            >
              <Box
                mt="2"
                height="640px"
                backgroundImage="url('/img/insurefarm-our-story.jpg')"
                backgroundSize="cover"
                borderRadius="8px"
                position="relative"
                zIndex="4"
              />
              {inView && (
                <motion.div
                  initial={{ x: "-20vw", opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.8, delay: 0.2 }}
                >
                  <Text
                    as="h2"
                    color="#333333"
                    fontSize={{ base: "32px", lg: "48px" }}
                    lineHeight={{ base: "40px", lg: "64px" }}
                    fontWeight="bold"
                    mb={{ base: 2, md: 4 }}
                  >
                    Our Story
                  </Text>
                  <Text
                    mb="2"
                    fontSize={{ base: "18px", md: "24px" }}
                    lineHeight="48px"
                    as="p"
                    color="primary.500"
                  >
                    We are a <strong>family business</strong> with{" "}
                    <strong>over 20 years</strong> of{" "}
                    <strong>combined experience</strong> in the{" "}
                    <strong>insurance industry</strong>.
                  </Text>
                  <Text
                    mb="2"
                    fontSize={{ base: "18px", md: "24px" }}
                    lineHeight="48px"
                    as="p"
                    color="primary.500"
                  >
                    We believe that what separates{" "}
                    <strong>our licensed agents</strong> from others is{" "}
                    <strong>
                      what happens before and after we enroll our clients
                    </strong>
                    .
                  </Text>
                  <Text
                    fontSize={{ base: "18px", md: "24px" }}
                    lineHeight="48px"
                    as="p"
                    color="primary.500"
                    mb={{ base: 6, md: 12 }}
                  >
                    Our agents have <strong>no deadlines</strong>. They will{" "}
                    <strong>sit with you</strong>
                    and do a <strong>full needs analysis</strong> to understand
                    what is most important to you. Then we will make suggestions
                    based on <strong>your budget</strong> and most importantly,{" "}
                    <strong>your needs</strong>.
                  </Text>
                  <Button
                    colorScheme="primary"
                    h="58px"
                    width="320px"
                    borderRadius="4px"
                    boxShadow="none"
                  >
                    READ MORE
                  </Button>
                </motion.div>
              )}
            </Box>
          )}
        </InView>
      </Box>

      <Box as="section" py="4rem" bg="primary.500" textAlign="center">
        <Box display="flex" flexDir="column">
          <Text
            as="h2"
            color="#FFFFFF"
            fontSize={{ base: "32px", lg: "48px" }}
            lineHeight={{ base: "40px", lg: "64px" }}
            fontWeight="bold"
            mb="2"
          >
            Our Offer
          </Text>
          <Text
            as="h3"
            color="#FFFFFF"
            fontSize={{ base: "24px", lg: "32px" }}
            lineHeight={{ base: "32pxpx", lg: "48px" }}
            fontWeight="300"
            mb="4"
          >
            Step-by-Step Guidance through the Entire Medicare Process
          </Text>
        </Box>
      </Box>

      <Box
        as="section"
        display="grid"
        height={{ base: "100%", md: "360px" }}
        gridTemplateColumns={{ base: "none", md: "repeat(4,1fr)" }}
        gridTemplateRows={{ base: "repeat(4,auto)", md: "none" }}
      >
        <InView triggerOnce>
          {({ inView, ref }) => (
            <Box
              height="100%"
              bg="#1E6E5A"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="#FFFFFF"
              ref={ref}
              py={{ base: "40px", md: 0 }}
            >
              {inView && (
                <motion.div
                  initial={{ opacity: 0, y: "80px" }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2, duration: 0.4 }}
                >
                  <Box
                    display="flex"
                    flexDir="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text fontWeight="bold" fontSize="64px" lineHeight="98px">
                      20+
                    </Text>
                    <Text fontSize="24px" fontWeight="medium">
                      Years of Experience
                    </Text>
                  </Box>
                </motion.div>
              )}
            </Box>
          )}
        </InView>
        <InView triggerOnce>
          {({ inView, ref }) => (
            <Box
              height="100%"
              bg="#459365"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="#FFFFFF"
              ref={ref}
              py={{ base: "40px", md: 0 }}
            >
              {inView && (
                <motion.div
                  initial={{ opacity: 0, y: "80px" }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.6, duration: 0.4 }}
                >
                  <Box
                    display="flex"
                    flexDir="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text fontWeight="bold" fontSize="64px" lineHeight="98px">
                      40+
                    </Text>
                    <Text fontSize="24px" fontWeight="medium">
                      Medicare Carriers
                    </Text>
                  </Box>
                </motion.div>
              )}
            </Box>
          )}
        </InView>
        <InView triggerOnce>
          {({ inView, ref }) => (
            <Box
              height="100%"
              bg="#77B76B"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="#FFFFFF"
              ref={ref}
              py={{ base: "40px", md: 0 }}
            >
              {inView && (
                <motion.div
                  initial={{ opacity: 0, y: "80px" }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 1, duration: 0.4 }}
                >
                  <Box
                    display="flex"
                    flexDir="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text fontWeight="bold" fontSize="64px" lineHeight="98px">
                      5000+
                    </Text>
                    <Text fontSize="24px" fontWeight="medium">
                      Happy Clients
                    </Text>
                  </Box>
                </motion.div>
              )}
            </Box>
          )}
        </InView>
        <InView triggerOnce>
          {({ inView, ref }) => (
            <Box
              height="100%"
              bg="#BCEBBB"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="primary.500"
              ref={ref}
              py={{ base: "40px", md: 0 }}
            >
              {inView && (
                <motion.div
                  initial={{ opacity: 0, y: "80px" }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 1.4, duration: 0.4 }}
                >
                  <Box
                    display="flex"
                    flexDir="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text fontWeight="bold" fontSize="64px" lineHeight="98px">
                      10000+
                    </Text>
                    <Text fontSize="24px" fontWeight="medium">
                      Seniors Helped
                    </Text>
                  </Box>
                </motion.div>
              )}
            </Box>
          )}
        </InView>
      </Box>

      <Box
        overflow="hidden"
        as="section"
        py="6rem"
        pb={{ base: "12rem", md: "8rem" }}
        bg="#FFFFFF"
        position="relative"
      >
        <Center mb={{ base: 6, md: 12 }}>
          <Text
            as="h2"
            fontWeight="bold"
            fontSize={{ base: "24px", lg: "32px" }}
            lineHeight={{ base: "32pxpx", lg: "48px" }}
            color="#333333"
          >
            What Our Clients Say
          </Text>
        </Center>
        <Box ref={testimonialsRef}>
          <TestimonialSlider />
        </Box>
        <AnimatePresence>
          {testimonialsInView && (
            <motion.div
              animate={{ opacity: 1, x: 0 }}
              initial={{ opacity: 0, x: "20vw" }}
              transition={{ duration: 0.8, delay: 0.4 }}
              exit={{ opacity: 0 }}
            >
              <Box
                position="absolute"
                right={{ base: 0, md: "4rem" }}
                bottom={{ base: "-6rem", md: 0 }}
                display={{ base: "flex", md: "initial" }}
                justifyContent="center"
                width={{ base: "100%", md: "initial" }}
              >
                <Button
                  colorScheme="primary"
                  boxShadow="none"
                  px="24px"
                  h={{ base: "48px", md: "58px" }}
                  borderRadius="40px"
                  fontSize={{ base: "16px", md: "18px" }}
                >
                  <Icon mr="8px" as={AiOutlinePhone} /> Speak with a Medicare
                  Specialist
                </Button>
              </Box>
            </motion.div>
          )}
        </AnimatePresence>
      </Box>

      <Box>
        <ContactSection />
      </Box>
    </Layout>
  );
};

export default LandingPage;
